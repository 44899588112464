@keyframes bouncedelay {
    0% {
        transform: rotate(360deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

$layout_size: 50; // ${props => props.size === 'small' ? 40 : (props.size === 'large' ? 60 : 50)}
$layout_color: #00adb5;
$first_size: 30px;

.holder {
    box-sizing: initial;
    align-self: center;
}
.holder > * {
    box-sizing: content-box;
}
.container {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-sizing: initial;
    height: 0;
    width: 4px;
    border-width: 0 4px 50px;
    border-style: none solid solid;
    border-color: transparent transparent #a863ff;
    position: relative;
    padding-top: 25px;
}

.center {
    width: 4px;
    height: 4px;
    border: 3px solid #a863ff;
    background: #a863ff;
    border-radius: 5px;
    transform: translateX(-3px) translateY(-4px);
    position: absolute;
    box-sizing: initial;
    z-index: 125;
}

.con {
    position: relative;
    animation: bouncedelay 3s infinite linear;
    /* box-sizing: initial;*/
}

.ItemFirst {
    height: 0;
    width: 0;
    border-width: 40px 3px 0 3px;
    border-style: solid solid none;
    border-color: #a863ff transparent transparent;
    transform-origin: 0 -2px;
    transform: rotate(60deg);
    position: absolute;
    box-sizing: initial;
}

.ItemSecond {
    height: 0;
    border-style: solid solid none;
    width: 0;
    border-width: 40px 2px 0 3px;
    border-color: #a863ff transparent transparent;
    transform-origin: 2px -1px;
    transform: rotate(180deg);
    position: absolute;
    box-sizing: initial;
}

.ItemThree {
    height: 0;
    width: 0;
    border-width: 40px 3px 0 3px;
    border-style: solid solid none;
    border-color: #a863ff transparent transparent;
    transform-origin: 5px 0;
    transform: rotate(300deg);
    position: absolute;
    box-sizing: initial;
}

//
//.container {
//    height: 0px;
//    width: 4px;
//    border-width: 0px 4px 50px;
//    border-style: none solid solid;
//    border-color: transparent transparent $layout_color;
//    position: relative;
//    padding-top: 25px;
//    box-sizing: initial;
//}
//
//.container {
//    margin: auto;
//    position: absolute;
//    left: 0px;
//    right: 0px;
//    top: 0px;
//    bottom: 0px;
//    box-sizing: initial;
//}
//
//.center {
//    width: 4px;
//    height: 4px;
//    border: 3px solid $layout_color;
//    background: rgb(255, 255, 255);
//    border-radius: 5px;
//    transform: translateX(-3px) translateY(-4px);
//    position: absolute;
//    box-sizing: initial;
//}
//
//.con {
//    position: relative;
//    animation: bouncedelay 5s infinite linear;
//    box-sizing: initial;
//}
//
//.ItemFirst { //${props => props.size === 'small' ? 24 : (props.size === 'large' ? 40 : 30)}
//    height: 0;
//    width: 2px;
//    border-width: $first_size 2px 0 2px;
//    border-style: solid solid none;
//    border-color: $layout_color transparent transparent;
//    transform-origin: 0 -2px;
//    transform: rotate(60deg);
//    position: absolute;
//    box-sizing: initial;
//}
//
//.ItemSecond {
//    height: 0;
//    width: 2px;
//    border-width: $first_size 2px 0 2px;
//    border-style: solid solid none;
//    border-color: $layout_color transparent transparent;
//    transform-origin: 2px -1px;
//    transform: rotate(180deg);
//    position: absolute;
//    box-sizing: initial;
//}
//
//.ItemThree {
//    height: 0;
//    width: 2px;
//    border-width: $first_size 2px 0 2px;
//    border-style: solid solid none;
//    border-color: $layout_color transparent transparent;
//    transform-origin: 5px 0;
//    transform: rotate(300deg);
//    position: absolute;
//    box-sizing: initial;
//}

.fontLoader {
    font-family: "Open Sans", sans-serif;
    color: rgba(255,255,255,0);
}

.fontLoader:after {
    font-family: "Open Sans", sans-serif;
    content: "display";
    font-weight: 500;
    position: absolute;
}


///


@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0)
    }

    to {
        -moz-transform: rotate(359deg)
    }
}

@-o-keyframes rotate {
    from {
        -o-transform: rotate(0)
    }

    to {
        -o-transform: rotate(359deg)
    }
}

@keyframes rotate {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(359deg)
    }
}

@-webkit-keyframes rotate2 {
    from {
        -webkit-transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes rotate2 {
    from {
        -moz-transform: rotate(0)
    }

    to {
        -moz-transform: rotate(359deg)
    }
}

@-o-keyframes rotate2 {
    from {
        -o-transform: rotate(0)
    }

    to {
        -o-transform: rotate(359deg)
    }
}

@keyframes rotate2 {
    from {
        transform: rotate(0)
    }

    to {
        transform: rotate(359deg)
    }
}

.windmill2 .swing,.windmill2 .swing2 {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0
}

.windmill2 {
    position: relative;
    margin: 50px auto;
    width: 100px;
    height: 120px
}

.windmill2 .pillar {
    position: absolute;
    top: 8px;
    left: 45px;
    display: block;
    height: 0;
    width: 4px;
    border-width: 0 4px 80px;
    border-style: none solid solid;
    border-color: transparent transparent dimgray;
}

.windmill2 .axis {
    position: absolute;
    left: 46px;
    width: 4px;
    height: 4px;
    border: 3px solid dimgray;
    border-radius: 5px;
    -webkit-animation: rotate 4s linear infinite;
    -moz-animation: rotate 4s linear infinite;
    -o-animation: rotate 4s linear infinite;
    animation: rotate 4s linear infinite
}

.windmill2 .swing,.windmill2 .swing2,.windmill2 .swing3 {
    position: absolute;
    display: block;
    height: 0;
    width: 2px;
    border-width: 50px 2px 0;
    border-style: solid solid none;
    border-color: dimgray transparent transparent
}

.windmill2 .swing {
    top: 1px;
    left: -2px;
    box-shadow: 1px 1px 1px rgba(105,97,97,.1);
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform-origin: 0 0;
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform-origin: 0 0;
    transform: rotate(60deg)
}

.windmill2 .swing2 {
    top: 0;
    left: 4.5px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform-origin: 0 0;
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform-origin: 0 0;
    transform: rotate(180deg)
}

.windmill2 .swing3 {
    top: 6px;
    left: 3px;
    -webkit-transform-origin: 0 0;
    -webkit-transform: rotate(300deg);
    -moz-transform-origin: 0 0;
    -moz-transform: rotate(300deg);
    -ms-transform-origin: 0 0;
    -ms-transform: rotate(300deg);
    -o-transform-origin: 0 0;
    -o-transform: rotate(300deg);
    transform-origin: 0 0;
    transform: rotate(300deg)
}
