//@import url('https://rsms.me/inter/inter.css');
//html { font-family: 'Inter', sans-serif; }
body {
    /*margin: 0;*/
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    background: rgb(237, 242, 247);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.open-sans {
    font-family: 'Open Sans', sans-serif;
}

//
//body {
//    max-width: 1100px;
//    margin-right: auto;
//    margin-left: auto;
//}
html, body {
    height: 100%;
    width: 100%;
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.app {
    justify-items: center;
}

.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: 0.875rem;
}

.text-base {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.125rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-3xl {
    font-size: 1.875rem;
}

.text-4xl {
    font-size: 2.25rem;
}

.text-5xl {
    font-size: 3rem;
}

.text-6xl {
    font-size: 4rem;
}


//
//body {
//    -moz-text-size-adjust: 100%;
//    -webkit-text-size-adjust: 100%;
//}
