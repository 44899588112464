.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.time {
    -webkit-font-smoothing: subpixel-antialiased;
    //font-family: 'Open Sans', sans-serif;
    font-size: 70px;
    font-weight: 700;
    letter-spacing: -1.01px;
    text-align: right;
    color: #161616;
}


.title {
    flex: 1;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: -0.8px;
    text-align: center;
    color: #616161; /*#898989;*/
    -webkit-font-smoothing: antialiased;
    //font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
}
