$shadow: rgba(0, 0, 0, 0.07);
$gray: rgb(128, 128, 129);
$solar_color: rgb(75, 186, 115);

.overview {
    display: flex;
    flex-direction: row;
    margin: 20px 10px 0;

    box-shadow: 0 0 5px 0 $shadow;
    border-radius: 2px;


    &-box {
        background: white;
        border-right: 1px solid #e2e8f0;
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 2rem;
        flex: 1.5;

        &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
    }
}

.overview-title {
    //font-size: 2.7rem;
    font-size: 2.6rem;

    font-weight: 600;
    //font-family: 'Open Sans', sans-serif;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1;

    letter-spacing: 0.7px;
    padding-bottom: 12px;
    color: #616161;
}

.overview-nm {
    color: $solar_color;
    //font-size: 3.6rem;

    font-size: 3.3rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 2px;

    > span {
        font-weight: 500;
        font-size: 2.2rem;
        letter-spacing: 0.5px;
        padding-left: 2px;
    }
}

.overview {
    &-env {
        padding-top: 0;
        padding-bottom: 0;
        //background: #4CAF50;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        color: #424242;
    }
}

.overview-env {
    align-items: flex-start;
    flex: 1.8;
}

.overview-env-row {
    display: flex;
    width: 100%;
    margin: 2px 0;

    align-items: center;

    svg {
        height: 54px;
        margin-right: 10%;
    }
}

.overview-env-row p {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
}

.overview-env-row span {
    font-size: 26px;
    font-weight: 600;
    color: #4bba73;
}
