//colors
$shadow: rgba(0, 0, 0, 0.07);
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$solar_color: rgb(75, 186, 115);
$load_color: rgb(255, 143, 0);
$load_color_light: rgb(255, 188, 15);
$grid_color_light: rgb(229, 57, 53);
$grid_color: rgb(230, 47, 44);
$gray: rgb(128, 128, 129);

.flows {
    display: flex;
    align-content: center;
    justify-items: center;
    align-self: center;
    position: relative;
    align-items: center;
    margin-top: 20px;
}

.chart-holder {
    box-shadow: 0 0 3px 0 $shadow;
}

.flow-panel {
    margin: 0 10px 10px;
    min-width: 430px;
    border-radius: 4px 4px 2px 2px;
    box-shadow: 0 0 5px 0 $shadow;
    background-color: $white;
    border-bottom: 3px solid $black;
    position: relative;
    //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    //font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: row;
}

.flow-panel--pv {
    border-bottom-color: $solar_color;
    padding-left: 15px;
    color: $solar_color;
}

.flow-panel--load {
    border-bottom-color: $load_color;
    padding-left: 15px;
    color: $load_color;

    .flow-icon {
        padding-top: 10px;
    }
}

.flow-panel--grid {
    padding-right: 15px;
    border-bottom-color: $grid_color_light;
    padding-left: 25px;
    color: $grid_color;

    .flow-right {
        padding: 0 20px;
    }

    .flow-icon {
        padding-top: 5px;
    }
}

.flow-left {
    text-align: center;
    padding: 16px 40px 16px 22px;
}

.flow-right {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0 70px 0 20px;
}

.flow-icon {
    height: 90px;
}

.flow-icon-text {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-top: 4px;
    text-transform: uppercase;
}

.flow-title {
    padding-top: 22px;
    font-weight: 400;
    font-size: 33px;
    line-height: 1;
    color: $gray;
    white-space: nowrap;
}

.kW {
    padding-top: 4px;
    font-size: 62px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 2px;
    white-space: nowrap;

    > span {
        font-size: 33px;
        letter-spacing: 0;
        padding-left: 2px;
    }
}

$triangle-size: 23px;
$triangle-half-size: ceil($triangle-size / 2);

.Triangle {
    border-top: $triangle-half-size solid transparent;
    border-bottom: $triangle-half-size solid transparent;
}

@each $side, $prop in ("Left": "border-right", "Right": "border-left") {
    @each $name, $color in ("Green": $solar_color, "Red": $grid_color, "Orange": $load_color_light) {
        .Triangle-#{$side}.Triangle-#{$name} {
            #{$prop}: $triangle-size solid $color;
        }
    }
}

.Triangle-Hidden {
    border-color: transparent !important;
}
